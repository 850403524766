import { useState } from 'react';
import {
  Group,
  Box,
  Collapse,
  Text,
  UnstyledButton,
  rem,
  Flex,
} from '@mantine/core';
import clsx from 'clsx';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { ReactComponent as SelectIcon } from '../../assets/icons/SelectIcon.svg';
import classes from './NavbarLinksGroup.module.css';
import { bodyTextColorHex, electricLimeHex } from '../../theme';
import { NavItemLink } from '../Navbar/types';

interface LinksGroupProps {
  icon?: React.ReactNode;
  label: string;
  to?: string;
  initiallyOpened?: boolean;
  showStudyNavItem?: boolean;
  active?: boolean;
  hidden?: boolean;
  links?: NavItemLink[];
}

export function LinksGroup({
  icon,
  to = '',
  label,
  initiallyOpened,
  showStudyNavItem,
  links,
  active,
  hidden,
}: LinksGroupProps) {
  const location = useLocation();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const isActive = active || matchPath(to || '', location.pathname);

  const items = (hasLinks ? links : []).map((link) => {
    if (link?.hidden) {
      return null;
    }
    const isLinkActive = matchPath(link.link, location.pathname);

    return (
      <Flex
        component={Link}
        className={clsx(classes.link, showStudyNavItem && classes.smallLink)}
        to={link.link}
        key={link.label}
        fw="normal"
        c={isLinkActive ? electricLimeHex : bodyTextColorHex}
      >
        {link.label}
      </Flex>
    );
  });

  if (hidden) {
    return null;
  }
  return (
    <>
      <UnstyledButton
        onClick={(e) => {
          if (hasLinks) {
            e.stopPropagation();
          }

          setOpened((o) => !o);
        }}
        className={classes.control}
      >
        <Group justify="space-between" gap={0}>
          <Box className={classes.linkContainer}>
            <Link
              className={clsx(
                classes.navLink,
                isActive && classes.navLinkActive,
                showStudyNavItem && classes.hideMargin,
              )}
              to={to}
              style={!to ? { pointerEvents: 'none' } : undefined}
            >
              <Flex align="center">
                {icon}
                {!showStudyNavItem && label}
              </Flex>
              {hasLinks && !showStudyNavItem && (
                <SelectIcon className={classes.linksIcon} />
              )}
            </Link>
          </Box>
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}
