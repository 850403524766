import React, { useState } from 'react';
import { isEmail, useForm } from '@mantine/form';
import { Button, Text } from '@mantine/core';
import classes from './ForgotPassword.module.css';
import Input from '../../../components/Inputs/Input';
import { sendForgotPasswordEmail } from '../../../api/auth';
import { useNavigate } from 'react-router-dom';
import { SING_IN_PATH } from '../../routes';

type FormValues = { email: string };
const ForgotPassword = () => {
  const navigate = useNavigate();
  const form = useForm<FormValues>({
    mode: 'controlled',
    initialValues: { email: '' },
    validate: {
      email: isEmail('Invalid email'),
    },
  });

  const [error, setError] = useState<string>('');
  const handleSubmit = async (values: FormValues) => {
    try {
      setError('');

      await sendForgotPasswordEmail(values);
      navigate(SING_IN_PATH);
    } catch (e: any) {
      console.error('e', e);
      setError(e?.message);
    }
  };
  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Input
        {...form.getInputProps('email')}
        mt="xl"
        label="Введіть ваш E-mail"
        placeholder="Введіть E-mail"
      />
      {error && (
        <Text mt="md" className={classes.errorField} c="red">
          {error}
        </Text>
      )}
      <Button
        type="submit"
        mt="xl"
        className={classes.submitButton}
        size="lg"
        fz="md"
        ml="auto"
        display="block"
      >
        Відправити код
      </Button>
    </form>
  );
};

export default ForgotPassword;
