import { FC } from 'react';
import { Flex, Title } from '@mantine/core';
import classes from '../Course.module.css';
import clsx from 'clsx';
import { Link, generatePath, useParams } from 'react-router-dom';
import { COURSE_LESSON_PATH } from '../../../routes';
import { Lesson } from '../../../../api/courses';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/ArrowRight.svg';

type Props = {
  lesson: Lesson;
  courseId: string;
  blockId: string;
  refetchCourseBlocks: () => Promise<any>;
};

const LessonComponent: FC<Props> = ({ lesson, courseId, blockId }) => {
  const { studyFlowId } = useParams();
  const allLessonBlocksAreEmpty = lesson.lessonBlocks
    ? lesson.lessonBlocks.every((lessonBlock) => !lessonBlock?.content)
    : true;

  const disableLesson = allLessonBlocksAreEmpty;
  return (
    <Flex
      className={clsx(classes.lesson, disableLesson && classes.disabled)}
      mb="12px"
      component={Link}
      to={generatePath(COURSE_LESSON_PATH, {
        studyFlowId: studyFlowId || '',
        courseId,
        blockId,
        lessonId: lesson._id,
      })}
    >
      <Title fz="14px" fw="500">
        {lesson.name}
      </Title>
      <Flex align="center">
        <ArrowRight
          style={{
            width: 20,
            height: 20,
          }}
        />
      </Flex>
    </Flex>
  );
};

export default LessonComponent;
