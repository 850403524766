import { Select } from '@mantine/core';
import classes from '../PageContainer.module.css';
import { ReactComponent as SelectIcon } from '../../../assets/icons/SelectIcon.svg';
import { useQuery } from '@tanstack/react-query';
import { getUserStudyFlows } from '../../../api/users';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { COURSE_PATH } from '../../../pages/routes';
import { StudyFlow } from '../../../api/studyFlows';
import { Course } from '../../../api/courses';
import { useMemo } from 'react';
import useUser from '../../../hooks/useUser';

type StudyFlowWithCourse = StudyFlow & { course: Course };

const CourseSelect = () => {
  const { courseId, studyFlowId } = useParams();
  const navigate = useNavigate();

  const { user } = useUser();

  const { data: userStudyFlows } = useQuery({
    queryKey: ['userStudyFlows'],
    queryFn: () => getUserStudyFlows(user._id),
  });

  const studyFlowsWithCourse = useMemo(
    () =>
      userStudyFlows?.filter(
        (studyFlow) => !!studyFlow?.course,
      ) as StudyFlowWithCourse[],
    [userStudyFlows],
  );
  const getDefaultValue = () => {
    if (courseId && studyFlowId) {
      return `${studyFlowId}_${courseId}`;
    }
    const firstStudyFlow = studyFlowsWithCourse && studyFlowsWithCourse[0];
    if (firstStudyFlow) {
      return `${firstStudyFlow?._id}_${firstStudyFlow?.course?._id}`;
    }
    return '';
  };

  return (
    <Select
      ml="32px"
      placeholder="Виберіть курс"
      onOptionSubmit={(value) => {
        const [studyFlowId, courseId] = value.split('_');
        navigate(
          generatePath(COURSE_PATH, {
            studyFlowId,
            courseId,
          }),
        );
      }}
      data={studyFlowsWithCourse?.map((studyFlow) => ({
        value: `${studyFlow._id}_${studyFlow.course?._id}` as string,
        label: `${studyFlow.course.name} (${studyFlow.name})`,
      }))}
      defaultValue={getDefaultValue()}
      rightSection={<SelectIcon />}
      classNames={{
        input: classes.select,
        section: classes.section,
        dropdown: classes.dropdown,
        option: classes.option,
      }}
    />
  );
};

export default CourseSelect;
