import {
  Box,
  Button,
  FileButton,
  Flex,
  Text,
  Title,
  UnstyledButton,
} from '@mantine/core';
import {
  HomeWork as HomeWorkType,
  HomeWorkStatus,
  Lesson,
  LessonBlock,
  submitHomework,
  getLessonGrade,
} from '../../../../api/courses';
import { FC, useState } from 'react';
import dayjs from 'dayjs';
import RichTextContent from '../../../../components/RichTextContent';
import Textarea from '../../../../components/Inputs/Textarea';
import { ReactComponent as AttachmentIcon } from '../../../../assets/icons/AttachmentIcon.svg';
import classes from '../Lesson.module.css';
import { bodyTextColorHex } from '../../../../theme';
import { fetchFile } from '../../../../api';
import { useQuery } from '@tanstack/react-query';
import useUser from '../../../../hooks/useUser';

type Props = {
  lesson: Lesson;
  lessonBlock: LessonBlock;
  homeworks?: HomeWorkType[];
  refetchHomeworks: () => Promise<any>;
  refetchCourse: () => Promise<any>;
};

const FIVE_MB = 5242880;

const formatDate = (date: string) =>
  dayjs(new Date(date)).format('DD.MM.YYYY | h:mm');

const HomeWork: FC<Props> = ({
  lesson,
  lessonBlock,
  homeworks,
  refetchHomeworks,
  refetchCourse,
}) => {
  const { user } = useUser();

  const { data: fetchedGrade } = useQuery({
    queryKey: ['grade', lessonBlock._id, user._id],
    queryFn: async () =>
      lessonBlock._id && user._id
        ? getLessonGrade(lessonBlock._id, user._id)
        : undefined,
  });

  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);

  const handleUpdateHomework = async () => {
    setError('');
    try {
      setIsLoading(true);

      let formData = new FormData();
      if (file) {
        formData.append('file', file);
      }
      formData.append('userComment', value);
      await submitHomework(lesson._id, formData);
      await refetchHomeworks();
      await refetchCourse();
      setIsLoading(false);
      setValue('');
    } catch (e: any) {
      setError(e?.message);
      setIsLoading(false);
    }
  };

  const lastHomework = homeworks?.length
    ? homeworks[homeworks?.length - 1]
    : null;

  const handleFileDownload = async (filePath: string, name: string) => {
    const blob = await fetchFile('/files-manager/download-file', filePath);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };
  return (
    <Flex
      className={classes.lessonContent}
      direction="column"
      justify="space-between"
    >
      <Flex direction="column" mb="50px">
        <Title order={4} mb="24px" fz="24px" fw="700">
          {lesson.name}
        </Title>
        <RichTextContent content={lessonBlock.content} />
      </Flex>
      {homeworks?.length ? (
        <Box h="100%">
          {homeworks.map((homeWork) => {
            const fileName = homeWork?.filePath?.split('/')[2];
            if (homeWork.status === HomeWorkStatus.Submitted) {
              return (
                <Flex direction="column" mb="40px">
                  <Flex mb="5px">
                    <Box className={classes.commentAuthor} mr="20px">
                      {homeWork.user.firstName}
                    </Box>
                    <Box className={classes.commentDate}>
                      {formatDate(homeWork.createdAt)}
                    </Box>
                  </Flex>
                  <Box className={classes.comment}>{homeWork.userComment}</Box>
                  {homeWork?.filePath && fileName && (
                    <File
                      name={fileName}
                      onClick={async () =>
                        handleFileDownload(homeWork?.filePath, fileName)
                      }
                    />
                  )}
                </Flex>
              );
            }
            return (
              <>
                <Flex direction="column" mb="40px">
                  <Flex mb="5px">
                    <Box className={classes.commentAuthor} mr="20px">
                      {homeWork.user.firstName}
                    </Box>
                    <Box className={classes.commentDate}>
                      {formatDate(homeWork.createdAt)}
                    </Box>
                  </Flex>
                  <Box className={classes.comment}>{homeWork.userComment}</Box>
                  {homeWork?.filePath && fileName && (
                    <File
                      name={fileName}
                      onClick={async () =>
                        handleFileDownload(homeWork?.filePath, fileName)
                      }
                    />
                  )}
                </Flex>
                <Flex direction="column" mb="40px">
                  <Flex mb="5px">
                    <Box className={classes.commentAuthor} mr="20px">
                      Ментор {homeWork.mentor?.firstName}
                    </Box>
                    <Box className={classes.commentDate}>
                      {formatDate(homeWork.updatedAt)}
                    </Box>
                  </Flex>

                  <Box className={classes.comment}>
                    {homeWork.mentorComment}
                  </Box>
                </Flex>
              </>
            );
          })}
        </Box>
      ) : null}
      {lastHomework?.status === HomeWorkStatus.Aproved ? (
        <Flex justify="space-between" align="center">
          <Box>
            <Text c="green">
              Домашнє завдання успішно виконане <br />
            </Text>
            {fetchedGrade?.grade && (
              <Text mt="md" fz="lg">
                Оцінка: <b>{fetchedGrade?.grade}</b>
              </Text>
            )}
          </Box>
        </Flex>
      ) : (
        <Box>
          <Flex
            justify="space-between"
            className={classes.homeWorkInputContainer}
            align="center"
          >
            <Textarea
              variant="black"
              size="md"
              onChange={(e) => setValue(e?.target?.value ?? '')}
              disabled={lastHomework?.status === HomeWorkStatus.Submitted}
              value={value}
              placeholder="Введіть коментар"
              className={classes.textarea}
              classNames={{ input: classes.textareaInput }}
            />

            <Flex className={classes.sendFileButton}>
              <FileButton
                onChange={(uploadedFile) => {
                  setError('');
                  if (!uploadedFile) {
                    return;
                  }
                  if (uploadedFile?.size > FIVE_MB) {
                    setError('Розмір файлу має бути меньше 5мб');
                    return;
                  }
                  setFile(uploadedFile);
                }}
                disabled={
                  !value || lastHomework?.status === HomeWorkStatus.Submitted
                }
              >
                {(props) => (
                  <UnstyledButton {...props} mr="24px">
                    <AttachmentIcon />
                  </UnstyledButton>
                )}
              </FileButton>
              <Button
                size="md"
                fz="md"
                display="block"
                disabled={
                  !value || lastHomework?.status === HomeWorkStatus.Submitted
                }
                onClick={handleUpdateHomework}
              >
                Відправити
              </Button>
            </Flex>
          </Flex>
          {file && <File name={file.name} />}
          {error && (
            <Text mt="md" c="red">
              {error}
            </Text>
          )}
        </Box>
      )}
    </Flex>
  );
};

const File = ({
  name,
  onClick,
}: {
  name: string;
  onClick?: () => Promise<void>;
}) => (
  <Flex
    mt="sm"
    display="flex"
    onClick={onClick}
    className={onClick && classes.file}
  >
    <AttachmentIcon />
    <Text ml="sm" c={bodyTextColorHex}>
      {name}
    </Text>
  </Flex>
);

export default HomeWork;
