import { FC } from 'react';
import classes from './Card.module.css';
import clsx from 'clsx';
import {
  Button,
  Card as ManCard,
  Image,
  Text,
  Title,
  BoxProps,
  Flex,
} from '@mantine/core';

type ListItemProps = BoxProps & {
  className?: string;
  children: React.ReactNode;
  image: string;
  title: string;
  subtitle: string;
  button: {
    label: string;
    onClick: () => void;
  };
};

const Card: FC<ListItemProps> = ({
  children,
  image,
  title,
  subtitle,
  button,
  ...rest
}) => {
  return (
    <ManCard
      shadow="none"
      radius="12px"
      withBorder
      bd="1px solid #151515"
      className={clsx(classes.card, rest.className)}
      p={20}
      {...rest}
    >
      <Flex direction="column" h="100%">
        <Image src={image} alt="img" mb="20px" className={classes.image} />
        <Text size="12px" mb="8px">
          {subtitle}
        </Text>
        <Title order={4} fz={20} fw="bolder" mb="8px">
          {title}
        </Title>
        {children}
      </Flex>
      <Button
        fullWidth
        radius="sm"
        fz="12px"
        fw="bolder"
        className={classes.button}
        onClick={button.onClick}
      >
        {button.label}
      </Button>
    </ManCard>
  );
};

export default Card;
