import classes from '../Lesson.module.css';
import { Title } from '@mantine/core';
import { FC } from 'react';
import { Lesson, LessonBlock } from '../../../../api/courses';
import { getYoutubeId } from '../../Course';

type Props = {
  lesson: Lesson;
  lessonBlock: LessonBlock;
};

const VideoMaterial: FC<Props> = ({ lesson, lessonBlock }) => {
  return (
    <div className={classes.lessonContent}>
      <Title order={4} mb="24px" fz="24px" fw="700">
        {lesson.name}
      </Title>
      <div>
        <iframe
          src={`https://www.youtube.com/embed/${getYoutubeId(lessonBlock.content)}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          className={classes.videoIframe}
        ></iframe>
      </div>
    </div>
  );
};

export default VideoMaterial;
