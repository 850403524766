import { Button, Flex, Image, Input, Text, Title } from '@mantine/core';
import PageContainer from '../../../components/PageContainer';
import EmptyUser from '../../../assets/images/EmptyUser.jpeg';
import { ReactComponent as FirstLeader } from '../../../assets/icons/FirstLeader.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/SearchIcon.svg';
import { ReactComponent as BackGreyIcon } from '../../../assets/icons/BackGreyIcon.svg';
import ListItem from '../../../components/ListItem';
import classes from './Leaders.module.css';
import { bodyTextColorHex, electricLimeHex } from '../../../theme';
import { useMemo, useState } from 'react';
import { COURSE_PATH } from '../../routes';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getGrades } from '../../../api/studyFlows';
import useUser from '../../../hooks/useUser';
import { User } from '../../../api/users';

const Leaders = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { studyFlowId, courseId } = useParams();
  const [search, setSearch] = useState('');

  const { data: grades, isFetched } = useQuery({
    queryKey: ['grades', studyFlowId],
    queryFn: async () => (studyFlowId ? getGrades(studyFlowId) : undefined),
  });
  const filteredData = useMemo(() => {
    const query = search.toLowerCase().trim();
    const searchKeys = ['lastName', 'firstName'] as (keyof User)[];
    return grades?.filter((item) =>
      searchKeys.some((key) => item.user[key].toLowerCase().includes(query)),
    );
  }, [grades, search]);

  if (!grades?.length) {
    if (isFetched) {
      navigate(
        generatePath(COURSE_PATH, {
          studyFlowId: studyFlowId || '',
          courseId: courseId || '',
        }),
      );
    }
    return null;
  }

  return (
    <PageContainer title="Всі лідери">
      <Flex className={classes.header}>
        <Button
          component={Link}
          leftSection={<BackGreyIcon />}
          className={classes.backButton}
          to={generatePath(COURSE_PATH, {
            studyFlowId: studyFlowId || '',
            courseId: courseId || '',
          })}
        >
          Повернутися на головну
        </Button>
        <Input
          placeholder="Пошук..."
          value={search}
          onChange={(e) => setSearch(e?.target?.value || '')}
          classNames={{
            input: classes.searchInput,
            section: classes.sectionInput,
            wrapper: classes.sectionWrapper,
          }}
          rightSection={<SearchIcon />}
        />
      </Flex>
      <div>
        {filteredData?.length ? (
          filteredData.map((grade, index) => (
            <ListItem
              key={grade.user._id}
              classNameContainer={
                user._id === grade.user._id ? classes.activeItem : undefined
              }
            >
              <Flex justify="space-between">
                <Flex align="center">
                  <Text fz="16px">{index + 1}</Text>
                  {index === 0 && (
                    <Flex
                      align="center"
                      justify="center"
                      ml="20px"
                      className={classes.firstLeader}
                    >
                      <FirstLeader />
                    </Flex>
                  )}

                  <Image src={EmptyUser} className={classes.image} />
                  <Text fz="16px">
                    {grade.user.firstName} {grade.user.lastName}
                  </Text>
                </Flex>
                <Text fz="16px" c={electricLimeHex} fw="700">
                  {grade.total}
                </Text>
              </Flex>
            </ListItem>
          ))
        ) : (
          <Title
            order={4}
            fz={20}
            fw="normal"
            c={bodyTextColorHex}
            mb={48}
            mt={70}
            ta="center"
          >
            Лідери не знайдені
          </Title>
        )}
      </div>
    </PageContainer>
  );
};

export default Leaders;
