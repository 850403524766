import React from 'react';
import AnonymousLayout from '../../../components/Layouts/AnonymousLayout';
import Form from './Form';
import { SIGN_UP_PATH } from '../../routes';

const SignIn = () => {
  return (
    <AnonymousLayout
      title="Вхід"
      subtitle="Новий користувач?"
      form={<Form />}
      actionLink={{ title: 'Створити новий аккаунт', to: SIGN_UP_PATH }}
    />
  );
};

export default SignIn;
