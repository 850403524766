import { Box, BoxProps, Button, Flex, Text, Title } from '@mantine/core';
import { FC } from 'react';
import classes from './SettingRow.module.css';

type Props = BoxProps & { label: string; value: string };

const SettingRow: FC<Props> = ({ label, value, ...props }) => (
  <Box {...props}>
    <Title c="white" mb="10px" fw="bold" fz="14px">
      {label}
    </Title>
    <Box className={classes.rowValue}>{value}</Box>
  </Box>
);
export default SettingRow;
