import React, { useState } from 'react';
import { hasLength, isEmail, useForm } from '@mantine/form';
import { Button, Text } from '@mantine/core';
import classes from './ResetPassword.module.css';
import PasswordInput from '../../../components/Inputs/PasswordInput';
import { resetPassword } from '../../../api/auth';
import { useNavigate } from 'react-router-dom';

type FormValues = { password: string; passwordConfirmation: string };

const ResetPasswordForm = ({ token }: { token: string }) => {
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');

  const form = useForm<FormValues>({
    mode: 'controlled',
    initialValues: { password: '', passwordConfirmation: '' },
    validate: {
      password: hasLength({ min: 8 }, 'Must be at least 8 characters'),
      passwordConfirmation: hasLength(
        { min: 8 },
        'Must be at least 8 characters',
      ),
    },
  });
  const handleSubmit = async (values: FormValues) => {
    try {
      setError('');

      await resetPassword({ ...values, resetToken: token });
      navigate('/');
    } catch (e: any) {
      console.error('e', e);
      setError(e?.message);
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <PasswordInput
        {...form.getInputProps('password')}
        mt="xl"
        type="password"
        label="Пароль"
        placeholder="Введіть пароль"
      />
      <PasswordInput
        {...form.getInputProps('passwordConfirmation')}
        mt="xl"
        type="password"
        label="Підтвердіть Пароль"
        placeholder="Підтвердіть пароль"
      />
      {error && (
        <Text mt="md" className={classes.errorField} c="red">
          {error}
        </Text>
      )}
      <Button
        type="submit"
        mt="xl"
        className={classes.submitButton}
        size="lg"
        fz="md"
        ml="auto"
        display="block"
      >
        Зберегти
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
