import classes from '../Lesson.module.css';
import { Title } from '@mantine/core';
import { FC } from 'react';
import { Lesson, LessonBlock } from '../../../../api/courses';
import RichTextContent from '../../../../components/RichTextContent';

type Props = {
  lesson: Lesson;
  lessonBlock: LessonBlock;
};

const StudyMaterial: FC<Props> = ({ lesson, lessonBlock }) => {
  return (
    <div className={classes.lessonContent}>
      <Title order={4} mb="24px" fz="24px" fw="700">
        {lesson.name}
      </Title>

      <RichTextContent content={lessonBlock.content} />
    </div>
  );
};

export default StudyMaterial;
