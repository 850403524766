import {
  COURSE_LEADERS_PATH,
  COURSE_LESSON_PATH,
  COURSE_PATH,
  COURSE_STUDY_PATH,
  RESOURCE_PATH,
} from '../pages/routes';

const coursePathes = [
  COURSE_PATH,
  COURSE_STUDY_PATH,
  COURSE_LESSON_PATH,
  RESOURCE_PATH,
  COURSE_LEADERS_PATH,
];

export default coursePathes;
