import { Box, Flex, Image, Text, Title } from '@mantine/core';
import { generatePath, Link, useParams } from 'react-router-dom';
import EmptyUser from '../../../../assets/images/EmptyUser.jpeg';
import { ReactComponent as FirstLeader } from '../../../../assets/icons/FirstLeader.svg';
import { ReactComponent as Trophy } from '../../../../assets/icons/Trophy.svg';
import { electricLimeHex } from '../../../../theme';
import clsx from 'clsx';
import { COURSE_LEADERS_PATH } from '../../../routes';
import classes from '../Course.module.css';
import { useQuery } from '@tanstack/react-query';
import { getGrades } from '../../../../api/studyFlows';
import useUser from '../../../../hooks/useUser';

const LeadersList = () => {
  const { user } = useUser();
  const { studyFlowId, courseId } = useParams();

  const { data: grades } = useQuery({
    queryKey: ['grades', studyFlowId],
    queryFn: async () => (studyFlowId ? getGrades(studyFlowId) : undefined),
  });
  return (
    <Box className={classes.pageContent} mt="0" mb="14px">
      <Flex
        mb="18px"
        pb="24px"
        className={classes.header}
        justify="space-between"
        align="center"
      >
        <Title order={5} fz={20} fw="normal">
          Лідери
        </Title>
        {!!grades?.length && (
          <Link
            to={generatePath(COURSE_LEADERS_PATH, {
              studyFlowId: studyFlowId || '',
              courseId: courseId || '',
            })}
            className={classes.leadersLink}
          >
            Всі Лідери
          </Link>
        )}
      </Flex>
      <Box
        className={
          !grades || grades?.length === 0 ? classes.emptyList : classes.list
        }
      >
        {grades?.length ? (
          <>
            <Flex
              mb="24px"
              direction="column"
              justify="center"
              align="center"
              pt="24px"
            >
              <Title order={5} fz={18} fw="normal" mb="18" c={electricLimeHex}>
                Топ 1
              </Title>
              <div style={{ position: 'relative' }}>
                <Flex
                  align="center"
                  justify="center"
                  className={classes.firstLeader}
                >
                  <FirstLeader />
                </Flex>
                <Image
                  src={EmptyUser}
                  alt="img"
                  className={classes.firstImage}
                />
              </div>
              <Title order={5} fz={16} fw="normal" mb="14">
                {grades[0].user.firstName} {grades[0].user.lastName}
              </Title>
              <Title order={5} fz={18} fw="normal" mb="18" c={electricLimeHex}>
                {grades[0].total}
              </Title>
            </Flex>
            <div>
              {grades.slice(1, 5).map((grade, index) => (
                <Flex
                  className={clsx(
                    classes.leader,
                    user._id === grade.user._id && classes.active,
                  )}
                >
                  <Flex>
                    <Text fz="14px" mr="14px">
                      {index + 2}
                    </Text>
                    <Image
                      src={EmptyUser}
                      alt="img"
                      className={classes.userImage}
                    />
                  </Flex>
                  <Text fz="12px">
                    {grade.user.firstName} {grade.user.lastName}
                  </Text>
                  <Text fz="14px">{grade.total}</Text>
                </Flex>
              ))}
            </div>
          </>
        ) : (
          <Trophy />
        )}
      </Box>
    </Box>
  );
};

export default LeadersList;
