import { FC } from 'react';
import { Flex, Text, Title } from '@mantine/core';
import classes from '../Course.module.css';
import { ReactComponent as CourseBlockIcon } from '../../../../assets/icons/CourseBlock.svg';
import { CourseBlock as CourseBlockType } from '../../../../api/courses';
import { bodyTextColorHex } from '../../../../theme';
import LessonComponent from './LessonComponent';

type Props = {
  courseBlock: CourseBlockType;
  courseId: string;
  refetchCourseBlocks: () => Promise<any>;
};

const CourseBlock: FC<Props> = ({
  courseBlock,
  courseId,
  refetchCourseBlocks,
}) => {
  const allLessonBlocksAreEmpty = courseBlock.courseLessons.every(
    (lesson) =>
      lesson.lessonBlocks &&
      lesson.lessonBlocks.every((lessonBlock) => !lessonBlock?.content),
  );

  if (allLessonBlocksAreEmpty) {
    return null;
  }
  return (
    <Flex className={classes.pageContent} pb="29px">
      <Flex align="center" mb="20px">
        <CourseBlockIcon />
        <Text fz={24} ml={20}>
          {courseBlock.name}
        </Text>
      </Flex>

      {courseBlock.courseLessons.length ? (
        <div className={classes.lessons}>
          {courseBlock.courseLessons.map((lesson) => (
            <LessonComponent
              key={lesson._id}
              lesson={lesson}
              courseId={courseId}
              blockId={courseBlock._id}
              refetchCourseBlocks={refetchCourseBlocks}
            />
          ))}
        </div>
      ) : (
        <Title order={4} fz={16} fw="normal" c={bodyTextColorHex} ta="center">
          Цей блок немає уроків
        </Title>
      )}
    </Flex>
  );
};

export default CourseBlock;
