import React, { useState } from 'react';
import AnonymousLayout from '../../../components/Layouts/AnonymousLayout';
import EmailForm from './Form';

const ForgotPassword = () => {
  return (
    <AnonymousLayout
      title="Відновити пароль"
      subtitle="Введіть ваш E-Mail для верифікації"
      form={<EmailForm />}
    />
  );
};

export default ForgotPassword;
