import React, { useState } from 'react';
import { Button, Flex, Image, Title } from '@mantine/core';
import AnonymousLayout from '../../../components/Layouts/AnonymousLayout';
import SignUpForm from './Form';
import { SING_IN_PATH } from '../../routes';
import LogoRed from '../../../assets/images/LogoRed.png';
import classes from './SignUp.module.css';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const navigate = useNavigate();
  const [showSuccessSection, setShowSuccessSection] = useState<boolean>(false);

  const SuccessSection = () => (
    <Flex className={classes.successSection}>
      <Image className={classes.logo} src={LogoRed} fit="contain" mb="30px" />
      <Title order={1} fz={40} fw={400} mb="30px" ta="center">
        Вітаємо на навчальній платформі Shark Trade!
      </Title>
      <Button
        onClick={() => navigate(SING_IN_PATH)}
        mt="xl"
        size="lg"
        fz="md"
        pr="56px"
        pl="56px"
        display="block"
      >
        Вхід
      </Button>
    </Flex>
  );

  return showSuccessSection ? (
    <SuccessSection />
  ) : (
    <AnonymousLayout
      title="Реєстрація"
      subtitle="У вас вже є аккаунт?"
      form={<SignUpForm onSubmit={() => setShowSuccessSection(true)} />}
      actionLink={{ title: 'Вхід', to: SING_IN_PATH }}
    />
  );
};

export default SignUp;
