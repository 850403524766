import React, { FC } from 'react';
import { Box, BackgroundImage, Title, Text, Image } from '@mantine/core';
import classes from './AnonymousLayout.module.css';
import BackgroundImageSrc from '../../../assets/images/SignUpBackground.png';
import LogoRed from '../../../assets/images/LogoRed.png';
import { primaryColorHex } from '../../../theme';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { SIGN_UP_PATH, SING_IN_PATH } from '../../../pages/routes';
import clsx from 'clsx';

type AnonymousLayoutProps = {
  form: React.ReactNode;
  title: string;
  subtitle: string;
  actionLink?: {
    title: string;
    to: string;
  };
};

const PartHeightPages = [SIGN_UP_PATH, SING_IN_PATH];

const AnonymousLayout: FC<AnonymousLayoutProps> = ({
  form,
  title,
  subtitle,
  actionLink,
}) => {
  const location = useLocation();
  const isMobile = useMediaQuery(`(max-width: 48em`);
  const isSignPage = location.pathname === SING_IN_PATH;
  const isFullHeightPage = !PartHeightPages.includes(location.pathname);

  return (
    <div
      className={clsx(
        classes.page,
        isFullHeightPage && isMobile && classes.white,
      )}
    >
      <div
        className={clsx(
          classes.formContainer,
          isFullHeightPage && isMobile && classes.fullHeight,
        )}
      >
        <div className={classes.form}>
          {isMobile && isFullHeightPage && (
            <Image className={classes.logoMobile} src={LogoRed} fit="contain" />
          )}

          <Title order={1} fz={isMobile ? 24 : 42} fw={400} mb={10}>
            {title}
          </Title>
          <Box display="flex" mb="30px">
            <Text size="md" mr="12px" fz={12}>
              {subtitle}
            </Text>
            {actionLink && !isMobile && (
              <Link to={actionLink.to} style={{ color: primaryColorHex }}>
                {actionLink.title}
              </Link>
            )}
          </Box>
          {form}
        </div>
      </div>
      <BackgroundImage
        className={clsx(
          classes.backgroundImage,
          isFullHeightPage && isMobile && classes.hide,
        )}
        src={BackgroundImageSrc}
        px="70px"
        py="94px"
      >
        {isMobile ? (
          <Box className={classes.mobileBlock}>
            <Title fw={400} fz={16} mb="14px" c="white" ta="center">
              Вітаємо на навчальній платформі <br />
              Shark Trade!
            </Title>
            <Box className={classes.buttonsBlock}>
              <Link
                to={SING_IN_PATH}
                className={clsx(
                  classes.mobileLink,
                  isSignPage && classes.active,
                )}
              >
                Вхід
              </Link>
              <Link
                to={SIGN_UP_PATH}
                className={clsx(
                  classes.mobileLink,
                  !isSignPage && classes.active,
                )}
              >
                Реєстрація
              </Link>
            </Box>
          </Box>
        ) : (
          <div className={classes.desktopImageContent}>
            <Image
              className={classes.logo}
              src={LogoRed}
              fit="contain"
              mb="30px"
            />
            <Title order={1} fz={40} fw={400} mb="14px">
              Вітаємо на навчальній платформі Shark Trade!
            </Title>
            <Text size="md" mr="12px" className={classes.text}>
              Якщо у вас виникнуть будь-які запитання щодо нашої платформи або у
              вас є які-небудь пропозиції, будь ласка, зв'яжіться з нами за
              адресою електронної пошти: <br />
              <Text size="lg">shark.help@gmail.com</Text>
            </Text>
          </div>
        )}
      </BackgroundImage>
    </div>
  );
};

export default AnonymousLayout;
