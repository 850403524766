import React, { FC } from 'react';
import clsx from 'clsx';
import {
  TextInput,
  InputProps,
  Input,
  Textarea as MantineTextarea,
  TextareaProps,
} from '@mantine/core';
import classes from './Input.module.css';

const Textarea: FC<TextareaProps> = ({ classNames, ...props }) => {
  return (
    <MantineTextarea
      {...props}
      autosize
      maxRows={6}
      classNames={{
        ...classNames,
        wrapper: props.variant === 'black' ? classes.blackWrapper : '',
        input:
          props.variant === 'black'
            ? clsx(classes.blackInput, (classNames as any)?.input)
            : classes.input,
      }}
      size={props?.size || 'lg'}
    />
  );
};

export default Textarea;
