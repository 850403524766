import { FC } from 'react';
import clsx from 'clsx';
import classes from './ListItem.module.css';

type ListItemProps = {
  className?: string;
  classNameContainer?: string;
  children: React.ReactNode;
  variant?: 'outlined' | 'contained';
};

const ListItem: FC<ListItemProps> = ({
  children,
  className,
  classNameContainer,
  variant = 'outlined',
}) => {
  const borderClass = variant === 'outlined' && classes.listItemOutlined;

  const background = variant === 'outlined' ? '#090909' : '#141414';
  return (
    <div
      className={clsx(
        classes.listItemContainer,
        borderClass,
        classNameContainer,
      )}
    >
      <div className={clsx(classes.listItem, className)} style={{ background }}>
        {children}
      </div>
    </div>
  );
};

export default ListItem;
