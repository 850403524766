import { ReqMethod, fetchHandler } from '..';
import { StudyFlow } from '../studyFlows';

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
  MENTOR = 'MENTOR',
}

export type User = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  telegramName: string;
  discordName: string;
  phoneNumber: string;
  role: UserRole;
};

type UpdatePassword = {
  password: string;
  passwordConfirmation: string;
};

export const getUsers = async (role?: UserRole): Promise<User[]> => {
  const path = role ? `/users?filter[role][eq]=MENTOR` : `/users`;
  return fetchHandler(path, {
    method: ReqMethod.GET,
  });
};
export const removeUser = async (id: string): Promise<User[]> =>
  fetchHandler(`/users/${id}`, {
    method: ReqMethod.DELETE,
  });

export const createUser = async (values: any) => {
  await fetchHandler(`/users`, {
    method: ReqMethod.POST,
    body: values,
  });
};
export const updateUser = async ({ _id, ...values }: User) => {
  await fetchHandler(`/users/${_id}`, {
    method: ReqMethod.PUT,
    body: values,
  });
};

export const updateUserPassword = async (values: UpdatePassword) => {
  await fetchHandler(`/users`, {
    method: ReqMethod.PUT,
    body: values,
  });
};

export const getUser = async (userId: string): Promise<User> =>
  await fetchHandler(`/users/${userId}`, {
    method: ReqMethod.GET,
  });

export const getMe = async (): Promise<User> =>
  await fetchHandler(`/users/me`, {
    method: ReqMethod.GET,
  });

export const getUserStudyFlows = async (userId: string): Promise<StudyFlow[]> =>
  await fetchHandler(`/users/${userId}/study-flows`, {
    method: ReqMethod.GET,
  });
