import { ReqMethod, fetchHandler } from '../index';
import { User } from '../users';

export enum LessonBlockType {
  LearningMaterial = 'learning-material',
  Video = 'video',
  Test = 'test',
  HomeWork = 'homework',
}
export type LessonBlock = {
  _id: string;
  name: string;
  content: string;
  type: LessonBlockType;
};
export type Lesson = {
  _id: string;
  name: string;
  grade?: number;
  blockId?: string;
  lessonBlocks: LessonBlock[] | null;
};

export type CourseBlock = {
  _id: string;
  name: string;
  courseLessons: Lesson[];
};

export type Course = {
  _id: string;
  name: string;
  description: string;
  features: string[];
  image: string;
  price: number;
  courseBlocks: CourseBlock[];
  courseResources?: string[];
};
type CreateCourseValues = {
  name: string;
  description: string;
  features: string[];
  image: string;
  price: number;
};
type CreateLessonBlockValues = {
  type: LessonBlockType;
  name: string;
  content: string;
};
type CreateLessonBlockParams = {
  courseId: string;
  blockId: string;
  lessonId: string;
  values: CreateLessonBlockValues;
};
type UpdateLessonBlockParams = {
  courseId: string;
  blockId: string;
  lessonId: string;
  lessonBlockId: string;
  content: string;
};
type RemoveLessonBlockParams = {
  courseId: string;
  blockId: string;
  lessonId: string;
  lessonBlockId: string;
};
export type Resource = {
  _id: string;
  title: string;
  text: string;
};
export enum HomeWorkStatus {
  Aproved = 'approved',
  Rejected = 'rejected',
  Submitted = 'submitted',
}
export type HomeWork = {
  _id: string;
  studyFlow: string;
  user: User;
  filePath: string;
  mentor: User | null;
  createdAt: string;
  updatedAt: string;
  status: HomeWorkStatus;
  courseLesson: Lesson;
  userComment?: string;
  mentorComment?: string;
};

export type Grade = {
  grade: number;
  lessonBlock: string;
  user: string;
  _id: string;
};

export const createCourse = async (values: CreateCourseValues) => {
  await fetchHandler(`/courses`, {
    method: ReqMethod.POST,
    body: values,
  });
};

export const getCourses = async (): Promise<Course[]> =>
  fetchHandler(`/courses`, {
    method: ReqMethod.GET,
  });

export const getCourse = async (id: string): Promise<Course> =>
  fetchHandler(`/courses/${id}`, {
    method: ReqMethod.GET,
  });

export const getCourseResources = async (id: string): Promise<Resource[]> =>
  fetchHandler(`/courses/${id}/resources`, {
    method: ReqMethod.GET,
  });

export const getCourseResource = async (
  id: string,
  resourceId: string,
): Promise<Resource> =>
  fetchHandler(`/courses/${id}/resources/${resourceId}`, {
    method: ReqMethod.GET,
  });

export const createCourseResource = async (
  id: string,
  title: string,
  text: string,
): Promise<Lesson> =>
  fetchHandler(`/courses/${id}/resources`, {
    method: ReqMethod.POST,
    body: { title, text },
  });

export const updateCourseResource = async (
  courseId: string,
  resorceId: string,
  title: string,
  text: string,
) => {
  await fetchHandler(`/courses/${courseId}/resources/${resorceId}`, {
    method: ReqMethod.PATCH,
    body: { title, text },
  });
};

export const removeCourseResource = async (
  courseId: string,
  resorceId: string,
) => {
  await fetchHandler(`/courses/${courseId}/resources/${resorceId}`, {
    method: ReqMethod.DELETE,
  });
};

export const createCourseBlock = async (id: string, name: string) => {
  await fetchHandler(`/courses/${id}/course-blocks`, {
    method: ReqMethod.POST,
    body: { name },
  });
};

export const removeCourseBlock = async (
  courseId: string,
  courseBlockId: string,
) => {
  await fetchHandler(`/courses/${courseId}/course-blocks/${courseBlockId}`, {
    method: ReqMethod.DELETE,
  });
};

export const getCourseBlocks = async (id: string): Promise<CourseBlock[]> =>
  fetchHandler(`/courses/${id}/course-blocks`, {
    method: ReqMethod.GET,
  });

export const createCourseLesson = async (
  id: string,
  blockId: string,
  name: string,
): Promise<Lesson> =>
  fetchHandler(`/courses/${id}/course-blocks/${blockId}/course-lessons`, {
    method: ReqMethod.POST,
    body: { name },
  });

export const removeCourseLesson = async (
  id: string,
  blockId: string,
  lessonId: string,
) => {
  await fetchHandler(
    `/courses/${id}/course-blocks/${blockId}/course-lessons/${lessonId}`,
    {
      method: ReqMethod.DELETE,
    },
  );
};
export const getCourseLesson = async (
  id: string,
  blockId: string,
  lessonId: string,
): Promise<Lesson> =>
  await fetchHandler(
    `/courses/${id}/course-blocks/${blockId}/course-lessons/${lessonId}`,
    {
      method: ReqMethod.GET,
    },
  );

export const createLessonBlock = async ({
  courseId,
  blockId,
  lessonId,
  values,
}: CreateLessonBlockParams): Promise<Lesson> =>
  fetchHandler(
    `/courses/${courseId}/course-blocks/${blockId}/course-lessons/${lessonId}/lesson-blocks`,
    {
      method: ReqMethod.POST,
      body: values,
    },
  );

export const updateLessonBlock = async ({
  courseId,
  blockId,
  lessonId,
  lessonBlockId,
  content,
}: UpdateLessonBlockParams): Promise<Lesson> =>
  fetchHandler(
    `/courses/${courseId}/course-blocks/${blockId}/course-lessons/${lessonId}/lesson-blocks/${lessonBlockId}`,
    {
      method: ReqMethod.PUT,
      body: { content },
    },
  );

export const removeLessonBlock = async ({
  courseId,
  blockId,
  lessonId,
  lessonBlockId,
}: RemoveLessonBlockParams): Promise<Lesson> =>
  fetchHandler(
    `/courses/${courseId}/course-blocks/${blockId}/course-lessons/${lessonId}/lesson-blocks/${lessonBlockId}`,
    {
      method: ReqMethod.DELETE,
    },
  );

export const getUserHomeworks = async (lessonId: string): Promise<HomeWork[]> =>
  fetchHandler(`/course-lessons/${lessonId}/user-homeworks`, {
    method: ReqMethod.GET,
  });

export const submitHomework = async (
  lessonId: string,
  body: any,
): Promise<void> =>
  fetchHandler(`/course-lessons/${lessonId}/user-homeworks`, {
    method: ReqMethod.POST,
    body,
  });

export const finishHomework = async (
  lessonId: string,
  body: any,
): Promise<void> =>
  fetchHandler(`/course-lessons/${lessonId}/finish`, {
    method: ReqMethod.POST,
    body,
  });

export const getLessonGrade = async (
  lessonBlockId: string,
  userId: string,
): Promise<Grade> =>
  fetchHandler(`/grades/lesson/${lessonBlockId}/users/${userId}`, {
    method: ReqMethod.GET,
  });
