import { Button, Flex, Title } from '@mantine/core';
import { FC } from 'react';
import classes from './PageContainer.module.css';
import UserIcon from '../UserIcon';
import { ReactComponent as BackButtonArrow } from '../../assets/icons/BackButtonArrow.svg';
import CourseSelect from './components/CourseSelect';
import {
  Link,
  generatePath,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom';
import { COURSE_LESSON_PATH, COURSE_STUDY_PATH } from '../../pages/routes';
import coursePathes from '../../constants/coursePathes';
import { useMediaQuery } from '@mantine/hooks';

type Props = {
  title: string;
  children: React.ReactNode;
};

const PageContainer: FC<Props> = ({ children, title }) => {
  const isMobile = useMediaQuery(`(max-width: 48em`);

  const location = useLocation();

  const { courseId, studyFlowId } = useParams();
  const showCourseSelect = coursePathes.some((to) =>
    matchPath(to, location.pathname),
  );
  const isLessonPage = matchPath(COURSE_LESSON_PATH, window.location.pathname);

  const BackToCourseButton = () => (
    <Button
      leftSection={<BackButtonArrow />}
      className={classes.backToCourseButton}
      component={Link}
      to={generatePath(COURSE_STUDY_PATH, {
        studyFlowId: studyFlowId || '',
        courseId: courseId || '',
      })}
    >
      Повернутися до всіх матеріалів
    </Button>
  );
  return (
    <div className={classes.page}>
      <Flex align="center" className={classes.header}>
        <Flex align="center">
          {isLessonPage ? (
            <BackToCourseButton />
          ) : (
            <>
              <Title order={3} className={classes.title}>
                {title}
              </Title>
              {showCourseSelect && <CourseSelect />}
            </>
          )}
        </Flex>
        {!isMobile && <UserIcon ml="auto" />}
      </Flex>
      {children}
    </div>
  );
};

export default PageContainer;
