import React, { FC, useState } from 'react';
import { TextInputProps } from '@mantine/core';
import classes from './PasswordInput.module.css';
import clsx from 'clsx';
import Input from '../Input';
import { ReactComponent as ShowPassword } from '../../../assets/icons/ShowPassword.svg';
import { ReactComponent as HidePassword } from '../../../assets/icons/HidePassword.svg';

const PasswordInput: FC<TextInputProps> = ({ classNames, ...props }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <Input
      {...props}
      type={showPassword ? 'text' : 'password'}
      rightSection={showPassword ? <HidePassword /> : <ShowPassword />}
      classNames={{
        wrapper: props.variant === 'black' ? classes.blackWrapper : '',
        input: props.variant === 'black' ? classes.blackInput : '',
      }}
      rightSectionProps={{
        className: classes.passwordIcon,
        onClick: () => setShowPassword((prev) => !prev),
      }}
    />
  );
};

export default PasswordInput;
