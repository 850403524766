import React, { useState } from 'react';
import { UseFormReturnType, hasLength, isEmail, useForm } from '@mantine/form';
import { Button, Box, Text } from '@mantine/core';
import classes from './SignUp.module.css';
import Input from '../../../components/Inputs/Input';
import PasswordInput from '../../../components/Inputs/PasswordInput';
import { signUp } from '../../../api/auth';

type SignUpFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  telegramName: string;
  discordName: string;
  phoneNumber: string;
  password: string;
  passwordConfirmation: string;
};
const SignUpForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const [error, setError] = useState<string>('');
  const form = useForm<SignUpFormValues>({
    mode: 'controlled',
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      telegramName: '',
      discordName: '',
      phoneNumber: '',
      password: '',
      passwordConfirmation: '',
    },
    validate: {
      password: hasLength({ min: 10 }, 'Must be at least 3 characters'),
      passwordConfirmation: hasLength(
        { min: 10 },
        'Must be at least 3 characters',
      ),
      email: isEmail('Invalid email'),
    },
  });

  const handleSubmit = async (values: SignUpFormValues) => {
    try {
      setError('');

      await signUp(values);
      onSubmit();
    } catch (e: any) {
      console.error('e', e);
      setError(e?.message);
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Box display="flex" w="100%">
        <Input
          required
          {...form.getInputProps('firstName')}
          label="Імʼя"
          placeholder="Введіть Імʼя"
          w="50%"
          pr="10px"
        />
        <Input
          required
          {...form.getInputProps('lastName')}
          label="Прізвище"
          placeholder="Введіть Прізвище"
          w="50%"
        />
      </Box>
      <Input
        required
        {...form.getInputProps('email')}
        mt="xl"
        label="E-mail"
        placeholder="Введіть E-mail"
      />
      <Input
        required
        {...form.getInputProps('phoneNumber')}
        mt="xl"
        label="Номер телефону"
        placeholder="Введіть ваш номер телефону"
      />
      <Input
        required
        {...form.getInputProps('telegramName')}
        mt="xl"
        label="Телеграм"
        placeholder="Введіть ваш нікнейм в Телеграм"
      />
      <Input
        required
        {...form.getInputProps('discordName')}
        mt="xl"
        label="Діскорд"
        placeholder="Введіть ваш нікнейм в Діскорд"
      />
      <PasswordInput
        required
        {...form.getInputProps('password')}
        mt="xl"
        type="password"
        label="Пароль"
        placeholder="Введіть пароль"
      />
      <PasswordInput
        required
        {...form.getInputProps('passwordConfirmation')}
        mt="xl"
        type="password"
        label="Підтвердіть Пароль"
        placeholder="Підтвердіть пароль"
      />
      {error && (
        <Text mt="md" className={classes.errorField} c="red">
          {error}
        </Text>
      )}
      <Button
        type="submit"
        mt="xl"
        className={classes.submitButton}
        size="lg"
        fz="md"
        ml="auto"
        display="block"
      >
        Реєстрація
      </Button>
    </form>
  );
};

export default SignUpForm;
