import classes from '../Lesson.module.css';
import { Button, Title } from '@mantine/core';
import { FC } from 'react';
import { Lesson, LessonBlock } from '../../../../api/courses';

type Props = {
  lesson: Lesson;
  lessonBlock: LessonBlock;
};

const Test: FC<Props> = ({ lesson, lessonBlock }) => {
  return (
    <div className={classes.lessonContent}>
      <Title order={4} mb="24px" fz="24px" fw="700">
        {lesson.name}
      </Title>

      <Button
        component="a"
        href={lessonBlock.content}
        target="_blank"
        size="lg"
        fz="md"
        mt="md"
      >
        Відкрити тест
      </Button>
    </div>
  );
};

export default Test;
