import React, { FC } from 'react';
import clsx from 'clsx';
import { TextInput, TextInputProps } from '@mantine/core';
import classes from './Input.module.css';

const Input: FC<TextInputProps> = ({ classNames, ...props }) => {
  return (
    <TextInput
      {...props}
      classNames={{
        ...classNames,
        wrapper: props.variant === 'black' ? classes.blackWrapper : '',
        input:
          props.variant === 'black'
            ? clsx(classes.blackInput, (classNames as any)?.input)
            : classes.input,
        label: classes.label,
      }}
      size={props?.size || 'lg'}
    />
  );
};

export default Input;
