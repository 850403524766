import {
  Box,
  FileButton,
  Flex,
  Image,
  Text,
  UnstyledButton,
} from '@mantine/core';
import PageContainer from '../../../components/PageContainer';
import classes from './Settings.module.css';
import SettingRow from './SettingRow';
import ResetPasswordForm from './Form';
import useUser from '../../../hooks/useUser';
import EmptyUser from '../../../assets/images/EmptyUser.jpeg';
import { ReactComponent as UploadAvatar } from '../../../assets/icons/UploadAvatar.svg';
import { useState } from 'react';

const TWO_MB = 2097152;

const SettingsPage = () => {
  const { user } = useUser();
  const [error, setError] = useState<string>('');
  const [file, setFile] = useState<string | null>(null);

  return (
    <PageContainer title="Налаштування">
      <div className={classes.pageContent}>
        {/* <FileButton
          accept="image/*"
          onChange={(uploadedFile) => {
            setError('');
            if (!uploadedFile) {
              return;
            }
            if (uploadedFile?.size > TWO_MB) {
              setError('Розмір файлу має бути меньше 2мб');
              return;
            }

            const url = URL.createObjectURL(uploadedFile);
            setFile(url);
          }}
        >
          {(props) => (
            <UnstyledButton {...props} mr="24px" pos="relative">
              <Image
                src={file || EmptyUser}
                alt="img"
                className={classes.avatar}
              />
              <Box className={classes.uploadAvatarIcon}>
                <UploadAvatar />
              </Box>
            </UnstyledButton>
          )}
        </FileButton>
        {error && (
          <Text mt="md" className={classes.errorField} c="red">
            {error}
          </Text>
        )} */}
        <Flex wrap="wrap" justify="space-between">
          <SettingRow
            className={classes.halfWidthSetting}
            label="Ім`я"
            value={user?.firstName}
          />
          <SettingRow
            className={classes.halfWidthSetting}
            label="Прізвище"
            value={user?.lastName}
          />
          <SettingRow
            className={classes.halfWidthSetting}
            label="Telegram"
            value={user?.telegramName}
          />
          <SettingRow
            className={classes.halfWidthSetting}
            label="Discord"
            value={user?.discordName}
          />
          <SettingRow
            w="100%"
            label="Номер телефону"
            value={user?.phoneNumber}
          />
        </Flex>
      </div>
      <div className={classes.pageContent}>
        <Box>
          <ResetPasswordForm />
        </Box>
      </div>
    </PageContainer>
  );
};

export default SettingsPage;
