import React, { useEffect } from 'react';
import AnonymousLayout from '../../../components/Layouts/AnonymousLayout';
import ResetPasswordForm from './Form';
import { useNavigate, useParams } from 'react-router-dom';
import { SING_IN_PATH } from '../../routes';

const ResetPassword = () => {
  const { resetId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!resetId) {
      navigate(SING_IN_PATH);
    }
  }, [resetId]);
  return (
    <AnonymousLayout
      title="Відновити пароль"
      subtitle="Введіть ваш E-Mail для верифікації"
      form={<ResetPasswordForm token={resetId || ''} />}
    />
  );
};

export default ResetPassword;
