import { Course } from '../courses';
import { ReqMethod, fetchHandler } from '../index';
import { User } from '../users';

export type StudyFlow = {
  _id: string;
  name: string;
  mentors: User[];
  users: User[];
  startDate: string;
  endDate: string;
  course?: Course;
};

export type Event = {
  _id: string;
  name: string;
  topic: string;
  link: string;
  startAt: string;
};
export type Grade = {
  _id: string;
  total: number;
  user: User;
};

export const createStudyFlow = async (
  values: Omit<StudyFlow, '_id' | 'users' | 'mentors'>,
) => {
  await fetchHandler(`/study-flows`, {
    method: ReqMethod.POST,
    body: values,
  });
};

export const removeStudyFlow = async (studyFlowId: string) => {
  await fetchHandler(`/study-flows/${studyFlowId}`, {
    method: ReqMethod.DELETE,
  });
};

export const getStudyFlows = async (): Promise<StudyFlow[]> =>
  await fetchHandler(`/study-flows`, {
    method: ReqMethod.GET,
  });
export const getNearestStudyFlows = async (): Promise<StudyFlow[]> =>
  await fetchHandler(`/study-flows/nearest`, {
    method: ReqMethod.GET,
  });

export const getStudyFlow = async (id: string): Promise<StudyFlow> =>
  await fetchHandler(`/study-flows/${id}`, {
    method: ReqMethod.GET,
  });

export const addStudyFlowCourse = async (
  studyFlowId: string,
  courseId: string,
) => {
  await fetchHandler(`/study-flows/${studyFlowId}/add-course/${courseId}`, {
    method: ReqMethod.PUT,
  });
};

export const removeStudyFlowCourse = async (studyFlowId: string) => {
  await fetchHandler(`/study-flows/${studyFlowId}/remove-course`, {
    method: ReqMethod.PUT,
  });
};

export const getStudyFlowUsers = async (studyFlowId: string): Promise<User[]> =>
  fetchHandler(`/study-flows/${studyFlowId}/users`, {
    method: ReqMethod.GET,
  });

export const addStudyFlowUser = async (studyFlowId: string, userId: string) => {
  await fetchHandler(`/study-flows/${studyFlowId}/add-user/${userId}`, {
    method: ReqMethod.PUT,
  });
};
export const addStudyFlowMentor = async (
  studyFlowId: string,
  userId: string,
) => {
  await fetchHandler(`/study-flows/${studyFlowId}/add-mentor/${userId}`, {
    method: ReqMethod.PUT,
  });
};

export const removeStudyFlowUser = async (
  studyFlowId: string,
  userId: string,
) => {
  await fetchHandler(`/study-flows/${studyFlowId}/remove-user/${userId}`, {
    method: ReqMethod.PUT,
  });
};

export const removeStudyFlowMentor = async (
  studyFlowId: string,
  userId: string,
) => {
  await fetchHandler(`/study-flows/${studyFlowId}/remove-mentor/${userId}`, {
    method: ReqMethod.PUT,
  });
};

export const getEvents = async (studyFlowId: string): Promise<Event[]> =>
  fetchHandler(`/study-flows/${studyFlowId}/events`, {
    method: ReqMethod.GET,
  });
export const getGrades = async (studyFlowId: string): Promise<Grade[]> =>
  fetchHandler(`/grades/study-flow/${studyFlowId}/leaderboard`, {
    method: ReqMethod.GET,
  });
