import { ReqMethod, fetchHandler } from '..';

type SignUpParams = {
  email: string;
  password: string;
  passwordConfirmation: string;
  firstName: string;
  lastName: string;
  discordName: string;
  telegramName: string;
  phoneNumber: string;
};
type SignInParams = {
  email: string;
  password: string;
};
type ResetPasswordParams = {
  resetToken: string;
  password: string;
  passwordConfirmation: string;
};

export const signUp = async (params: SignUpParams) => {
  await fetchHandler(
    `/auth/sign-up`,
    {
      method: ReqMethod.POST,
      body: params,
    },
    true,
  );
};
export const logOut = async () => {
  await fetchHandler(
    `/auth/logout`,
    {
      method: ReqMethod.POST,
    },
    true,
  );
};
export const signIn = async (params: SignInParams) => {
  await fetchHandler(
    `/auth/sign-in`,
    {
      method: ReqMethod.POST,
      body: { ...params, method: 'client' },
    },
    true,
  );
};

export const sendForgotPasswordEmail = async (params: { email: string }) => {
  await fetchHandler(
    `/reset-password/forgot`,
    {
      method: ReqMethod.POST,
      body: params,
    },
    true,
  );
};

export const resetPassword = async (params: ResetPasswordParams) => {
  await fetchHandler(
    `/reset-password/reset`,
    {
      method: ReqMethod.POST,
      body: params,
    },
    true,
  );
};
