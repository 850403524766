import { useQuery } from '@tanstack/react-query';
import classes from './Lesson.module.css';
import PageContainer from '../../../components/PageContainer';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';
import {
  getCourseLesson,
  Lesson as LessonType,
  LessonBlockType,
  LessonBlock,
  getCourse,
  CourseBlock,
  finishHomework,
  getUserHomeworks,
  HomeWorkStatus,
} from '../../../api/courses';
import { Button, Flex } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import StudyMaterial from './components/StudyMaterial';
import VideoMaterial from './components/VideoMaterial';
import { ReactComponent as RightArrow } from '../../../assets/icons/ArrowRight.svg';
import HomeWork from './components/HomeWork';
import { COURSE_LESSON_PATH, ROOT_PATH } from '../../routes';
import Test from './components/Test';

const navLabels = {
  [LessonBlockType.LearningMaterial]: 'Навчальний матеріал',
  [LessonBlockType.Video]: 'Відео-матеріал',
  [LessonBlockType.Test]: 'Тест',
  [LessonBlockType.HomeWork]: 'Домашнє завдання',
};

const getLessonBlock = (
  lesson: LessonType,
  type: LessonBlockType,
): LessonBlock | undefined => {
  const lessonBlock = lesson.lessonBlocks
    ? lesson.lessonBlocks.find((block) => block.type === type)
    : undefined;
  return lessonBlock;
};

const Lesson = () => {
  const navigate = useNavigate();
  const { courseId, blockId, lessonId } = useParams();

  const { data: lesson, isFetched: isFetchedLesson } = useQuery({
    queryKey: ['lesson', courseId, blockId, lessonId],
    queryFn: async () =>
      courseId && blockId && lessonId
        ? getCourseLesson(courseId, blockId, lessonId)
        : undefined,
  });
  const {
    data: course,
    isFetched,
    refetch: refetchCourse,
  } = useQuery({
    queryKey: ['course', courseId],
    queryFn: async () => (courseId ? getCourse(courseId) : undefined),
  });

  const lessonBlocks = useMemo(
    () =>
      Object.values(LessonBlockType)
        .map((type) =>
          lesson && !!getLessonBlock(lesson, type)?.content
            ? getLessonBlock(lesson, type)
            : null,
        )
        .filter(Boolean) as LessonBlock[],
    [lesson],
  );

  const nextLesson = useMemo(() => {
    if (!lesson) {
      return undefined;
    }
    let nextLesson = undefined as LessonType | undefined;
    let nextCourseBlock = undefined as CourseBlock | undefined;
    course?.courseBlocks?.forEach((courseBlock, index) => {
      const currentLessonIndex = courseBlock.courseLessons.findIndex(
        (les) => les._id === lesson?._id,
      );
      const nextLessonInCurrentBlock = courseBlock.courseLessons[
        currentLessonIndex + 1
      ] as LessonType;
      const courseBlockPlusOne = course?.courseBlocks[index + 1];
      const courseBlockPlusOneLesson = courseBlockPlusOne
        ? courseBlockPlusOne.courseLessons[0]
        : undefined;
      nextLesson = (nextLessonInCurrentBlock ||
        courseBlockPlusOneLesson) as LessonType;
      nextCourseBlock = (
        nextLessonInCurrentBlock ? courseBlock : courseBlockPlusOne
      ) as CourseBlock;
    });

    if (nextLesson && nextCourseBlock) {
      return { ...nextLesson, blockId: nextCourseBlock._id };
    }
    return undefined;
  }, [course, lesson]);
  if (!course || !lesson?.lessonBlocks?.length) {
    if (isFetched && isFetchedLesson) {
      navigate(ROOT_PATH);
    }
    return null;
  }

  return (
    <PageContainer title={lesson.name}>
      <PageContent
        lessonBlocks={lessonBlocks}
        lesson={lesson}
        nextLesson={nextLesson}
        refetchCourse={refetchCourse}
      />
    </PageContainer>
  );
};

const PageContent = ({
  lessonBlocks,
  lesson,
  nextLesson,
  refetchCourse,
}: {
  lessonBlocks: LessonBlock[];
  lesson: LessonType;
  refetchCourse: () => Promise<any>;
  nextLesson?: LessonType;
}) => {
  const navigate = useNavigate();

  const { courseId, studyFlowId } = useParams();

  const [selectedLessonBlockType, setSelectedLessonBlockType] =
    useState<LessonBlockType>(() => lessonBlocks[0].type);

  const { data: homeworks, refetch: refetchHomeworks } = useQuery({
    queryKey: ['homeworks', lesson._id],
    queryFn: () => getUserHomeworks(lesson._id),
  });
  const sortedHomeworks = homeworks?.sort(
    (first, second) =>
      new Date(first.createdAt).getTime() -
      new Date(second.createdAt).getTime(),
  );
  const lastHomework = sortedHomeworks?.length
    ? sortedHomeworks[sortedHomeworks?.length - 1]
    : null;
  const homeworkInLessonIsExisted = lessonBlocks.some(
    (lesson) => lesson.type === LessonBlockType.HomeWork,
  );

  useEffect(() => {
    const newType = lessonBlocks[0].type;
    setSelectedLessonBlockType((prev) => (prev !== newType ? newType : prev));
  }, [lessonBlocks]);

  const Content = () => {
    const lessonBlock = getLessonBlock(lesson, selectedLessonBlockType);
    if (!lessonBlock?.content) {
      return null;
    }

    switch (selectedLessonBlockType) {
      case LessonBlockType.LearningMaterial:
        return <StudyMaterial lesson={lesson} lessonBlock={lessonBlock} />;
      case LessonBlockType.Video:
        return <VideoMaterial lesson={lesson} lessonBlock={lessonBlock} />;
      case LessonBlockType.Test:
        return <Test lesson={lesson} lessonBlock={lessonBlock} />;
      case LessonBlockType.HomeWork:
        return (
          <HomeWork
            lesson={lesson}
            lessonBlock={lessonBlock}
            homeworks={sortedHomeworks}
            refetchHomeworks={refetchHomeworks}
            refetchCourse={refetchCourse}
          />
        );
      default:
        return null;
    }
  };
  const showNextPageButton = homeworkInLessonIsExisted
    ? lastHomework?.status === HomeWorkStatus.Aproved && nextLesson
    : nextLesson;
  return (
    <div className={classes.pageContent}>
      <Flex className={classes.lessonNav}>
        {lessonBlocks.map((lessonBlock) => (
          <div
            key={lessonBlock.type}
            className={clsx(
              classes.navItem,
              lessonBlock.type === selectedLessonBlockType && classes.active,
            )}
            onClick={() => setSelectedLessonBlockType(lessonBlock.type)}
          >
            {navLabels[lessonBlock.type]}
          </div>
        ))}
        {showNextPageButton && (
          <Button
            size="sm"
            fz="sm"
            mt="20px"
            display="block"
            className={classes.nextPageButton}
            rightSection={<RightArrow />}
            classNames={{ inner: classes.nextPageButtonInner }}
            onClick={async () => {
              const homeworkInLessonIsExisted = lessonBlocks.some(
                (lesson) => lesson.type === LessonBlockType.HomeWork,
              );
              const nextPageUrl = generatePath(COURSE_LESSON_PATH, {
                studyFlowId: studyFlowId || '',
                courseId: courseId || '',
                blockId: nextLesson?.blockId || '',
                lessonId: nextLesson?._id || '',
              });
              if (homeworkInLessonIsExisted) {
                navigate(nextPageUrl);
                return;
              }

              const nextLessonBlocksAreEmpty =
                !nextLesson?.lessonBlocks ||
                nextLesson?.lessonBlocks?.every(
                  (lessonBlock) => !lessonBlock?.content,
                );
              if (nextLessonBlocksAreEmpty) {
                let formData = new FormData();

                formData.append('userComment', 'finish');
                await finishHomework(lesson._id, formData);
                await refetchCourse();
              }

              navigate(nextPageUrl);
            }}
          >
            Наступний урок
          </Button>
        )}
      </Flex>
      <Content />
    </div>
  );
};

export default Lesson;
