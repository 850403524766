import { Box, Flex, Image, Text, Title } from '@mantine/core';
import PageContainer from '../../../components/PageContainer';
import classes from './Course.module.css';
import EventsList from './components/EventsList';
import { generatePath, Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCourse, Lesson, LessonBlockType } from '../../../api/courses';
import { bodyTextColorHex } from '../../../theme';
import { COURSE_LESSON_PATH } from '../../routes';
import LeadersList from './components/LeadersList';

export function getYoutubeId(url: string) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

const Course = () => {
  const { courseId, studyFlowId } = useParams();

  const { data: course } = useQuery({
    queryKey: ['course', courseId],
    queryFn: async () => (courseId ? getCourse(courseId) : undefined),
  });

  const lessons = course?.courseBlocks?.reduce((acc, cur) => {
    const lessons = cur.courseLessons
      .filter(
        (lesson) =>
          lesson.lessonBlocks &&
          lesson.lessonBlocks.some((lessonBlock) => !!lessonBlock?.content),
      )
      .map((lesson) => ({ ...lesson, blockId: cur._id }));

    return [...acc, ...lessons];
  }, [] as Lesson[]);
  return (
    <PageContainer title="Загальне">
      <Flex className={classes.pageContainer}>
        <Box className={classes.pageContent} w="100%" m="7px">
          <Flex mb="18px" pb="24px" className={classes.header}>
            <Title order={5} fz={20} fw="normal">
              Доступні матеріали для перегляду
            </Title>
          </Flex>
          <Flex gap="12px" wrap="wrap" className={classes.lessons}>
            {lessons?.length ? (
              (lessons?.length > 3 ? lessons.slice(1).slice(-3) : lessons).map(
                (lesson) => {
                  const videoBlockUrl = lesson.lessonBlocks?.find(
                    (lessoBlock) => lessoBlock.type === LessonBlockType.Video,
                  )?.content;
                  const url = videoBlockUrl
                    ? `https://img.youtube.com/vi/${getYoutubeId(videoBlockUrl)}/default.jpg`
                    : null;

                  return (
                    <Flex
                      className={classes.lessonBlock}
                      component={Link}
                      to={generatePath(COURSE_LESSON_PATH, {
                        studyFlowId: studyFlowId || '',
                        courseId: courseId || '',
                        blockId: lesson?.blockId || '',
                        lessonId: lesson._id,
                      })}
                    >
                      {url && <Image src={url} mah="120px" mb="20px" />}
                      <Text className={classes.lessonName}>{lesson.name}</Text>
                    </Flex>
                  );
                },
              )
            ) : (
              <>
                <Title
                  order={4}
                  fz={20}
                  fw="normal"
                  c={bodyTextColorHex}
                  mb={48}
                  mt={10}
                  ta="center"
                >
                  В цього курсу немає матеріалів
                </Title>
              </>
            )}
          </Flex>
        </Box>
        <Box className={classes.rightBlock} m="7px">
          <LeadersList />
          <EventsList />
        </Box>
      </Flex>
    </PageContainer>
  );
};

export default Course;
